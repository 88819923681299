import { mutations } from '@/state/store.js'
import navLink from '@/config/constants/navLink.js'
import subdomain from './constants/subdomain.js'
import fixedDomains from '@/config/constants/fixedDomains.js'

export function setEnvConfig(host) {
    
    const partsOfUrl = host.split('.');

    
    if (Object.values(fixedDomains).includes(partsOfUrl[0]) && ['dev', 'pre', 'pro'].indexOf(partsOfUrl[1]) === -1) {
        return setLanding('', partsOfUrl[0], true, partsOfUrl[1])
    }
    if (partsOfUrl[1] === "localhost:8080") { //Local environment
        return setLanding(partsOfUrl[0], partsOfUrl[1])
    } else if (partsOfUrl[1] === "insurama") { //Redegal des2 environment
        return setLanding(partsOfUrl[0], partsOfUrl[1] + "." + partsOfUrl[2] + "." + partsOfUrl[3])
    } else if (['dev', 'pre', 'pro'].indexOf(partsOfUrl[1]) !== -1) {
        const landing = partsOfUrl.shift()
        const domain = partsOfUrl.join('.')
        return setLanding(landing, domain)
    } else if (process.env.VUE_APP_LANDING && process.env.VUE_APP_DOMAIN) {
        return setLanding(process.env.VUE_APP_LANDING, process.env.VUE_APP_DOMAIN)
    }
}

function setLanding(subdomain, domain, fixedSubdomain = false, extension = 'com' ) {
    let currentLanding
    if (fixedSubdomain === true) {
        mutations.setCurrentLanding(Object.keys(fixedDomains).find(key => fixedDomains[key] === domain));
        setNavigationLinks(fixedDomains[domain], false, extension)
        return true;
    } else {
        currentLanding = mutations.setCurrentLanding(subdomain);
        if (currentLanding !== '') {
            setNavigationLinks(domain)
            return true;
        }
    }
    return false;
}

function setNavigationLinks(domain, hasSubdomain = true, extension = 'com' ) {
    Object.keys(navLink).forEach(link => {
        if(!hasSubdomain ){
            if(link !== 'insurama'){
                navLink[link] = `${window.location.protocol}//${fixedDomains[link] + '.com'}`
            }
            else{
                navLink[link] = `${window.location.protocol}//${fixedDomains[link] + '.' + extension}`
            }
            
        }
        else{
            navLink[link] = `${window.location.protocol}//${subdomain[link] + '.' + domain}`
        }
    })
}