<template>
  <div id="app">
    <transition appear name="fade" mode="out-in">
        <div v-if="isLoading" class="loading-overlay" key="loading">
            <img src='@/assets/loader.gif' height=64>
        </div>
        <div v-else>
            <router-view :key="$route.fullPath"/>
        <!-- <LanguageSwitcher v-on:localeChange="loadLocaleMessages" /> -->
        </div>
    </transition>
  </div>
</template>

<script>
//import LanguageSwitcher from './components/LanguageSwitcher.vue'
import { loadLocaleMessagesAsync } from "@/i18n"
import { actions, getters } from '@/state/store.js'

export default {
  data: () => ({
    isLoading: true
  }),
  beforeMount(){
    this.setFavText()
  },
  watch: {
    $route: {
    handler: function(){
        this.pushUTM()
      },
      deep: true
    }
  },
  async mounted() {
    await this.initializeSite()

    if (!window.inject) {
        this.$cookiebot.consentBanner()
    }
  },
  computed: {
    currentLanding: getters.currentLanding
  },
  methods: {
    /**
     * Initializes the site with all the basic data, keeping a load indicator while it does that
     */
    async initializeSite () {
        this.isLoading = true
        return Promise.all([
            this.loadLocaleMessages(this.$i18n.locale),
            actions.getProducts()
        ])
        // If something fails at this point is critical, as some required data/locales are missing
        .catch(() => {
            this.$router.push({ name: 'error'})
        })
        .then(() => this.setFavicon() )
        // If something fails at this point is not critical, so we can continue with the normal page load
        .catch((err) => {
            console.warn(err)
        })
        .finally(() => this.isLoading = false )
    },
    loadLocaleMessages(locale) {
      localStorage.setItem("appLanguage", locale)
      return loadLocaleMessagesAsync(locale)
    },
    setFavicon() {
      var favicon = document.querySelector("link[rel~='icon']");
      favicon.href = require(`@/assets/fav-icons/${this.$i18n.locale}_${this.currentLanding}.png`)
    },
    setFavText(){
      var title = ''
      if(this.isCurrentLanding('seguromovil')){
        title = 'seuSegurotelemóvel'
      }else if(this.isCurrentLanding('seguromultidispositivo')){
        title = 'seuSeguromulti-dispositivos'
      }else if(this.isCurrentLanding('seguroportatil')){
        title = 'seuSegurolaptop'
      }else if(this.isCurrentLanding('insurama')) {
        title = 'Insurama'
      }else{
        title = ""
      }

      window.document.title = title
    },
    isCurrentLanding(insurance) {
      return insurance === this.currentLanding
    }
  }
}
</script>

<style>
.loading-overlay {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>