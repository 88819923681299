export default {
    seguromovil: {
        "name": "seguromovil",
        "product_type_id": "1"
    },
    seguromultidispositivo: {
        "name": "seguromultidispositivo",
        "product_type_id": "5"
    },
    seguroportatil: {
        "name": "seguroportatil",
        "product_type_id": "6"
    }
}