const URL_INSURAMA = process.env.VUE_APP_INSURAMA_API_URL

var buildUrl = function(base, path) {
    return `${base}/${path}`
}

var buildUrlInsurama = function(path) {
    return buildUrl(URL_INSURAMA, path)
}

export default {
    DEVICE_MODELS: buildUrlInsurama('models'),
    CONTACT_POST: buildUrlInsurama('email-cliente'),
    APP_STORES_LINKS: buildUrlInsurama('get-app-stores-link')
}