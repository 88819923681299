const pageRoutes = {
    initial: '/',
    cookies: '/cookies',
    order: '/order',
    ordererror: '/order-error',
    orderok: '/order-ok',
    error: '/error',
    contact: '/contacto',
    map: '/mapa',
    legalwarning: '/aviso-legal',
    privacity: '/privacidade',
    contractingconditions: '/condicoes-gerais'
}
module.exports = pageRoutes