<template>
    <svg
      :class="`mdi mdi-${icon}`"
      fill="currentColor"
      width="24"
      height="24"
    >
      <path :d="pathData" />
    </svg>
  </template>
  
  <script>
  import * as mdi from '@mdi/js'; // Import all icons
  
  export default {
    name: 'MdiIcon',
    props: {
      icon: {
        type: String,
        required: true,
      },
    },
    computed: {
      pathData() {
        return mdi[this.icon] || ''; // Returns the path of the icon based on the icon name
      },
    },
  };
  </script>
  