import Vue from "vue"
import VueI18n from "vue-i18n"
import getBrowserLocale from "@/i18n/utils/get-browser-locale"
import { supportedLocalesInclude } from "@/i18n/utils/supported-locales"
import dateTimeFormats from "@/locales/date-time-formats"
import numberFormats from "@/locales/number-formats"

Vue.use(VueI18n)

function getStartingLocale() {
    if (localStorage.getItem("appLanguage")) {
        return localStorage.getItem("appLanguage")
    } else {
        const browserLocale = getBrowserLocale({ countryCodeOnly: true })
        if (supportedLocalesInclude(browserLocale)) {
            localStorage.setItem("appLanguage", browserLocale)
            return browserLocale
        } else {
            localStorage.setItem("appLanguage", process.env.VUE_APP_I18N_LOCALE || "pt")
            return process.env.VUE_APP_I18N_LOCALE || "pt"
        }
    }
}

const startingLocale = getStartingLocale()

const i18n = new VueI18n({
    locale: startingLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "pt",
    messages: {},
    dateTimeFormats,
    numberFormats
})

const loadedLanguages = []

export function loadLocaleMessagesAsync(locale) {
    if (loadedLanguages.length > 0 && i18n.locale === locale) {
        return Promise.resolve(locale)
    }

    // If the language was already loaded
    if (loadedLanguages.includes(locale)) {
        i18n.locale = locale
        return Promise.resolve(locale)
    }

    // If the language hasn't been loaded yet
    return import (
        `@/locales/${process.env.VUE_APP_COUNTRY_CODE}/${locale}.json`
    ).then(messages => {
        i18n.setLocaleMessage(locale, messages.default)
        loadedLanguages.push(locale)
        i18n.locale = locale
        return Promise.resolve(locale)
    })
}

loadLocaleMessagesAsync(startingLocale)

export default i18n