const contactInfo = {}

if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
    contactInfo['seguromovilTelephone'] = '308802868'
    contactInfo['seguromultidispositivoTelephone'] = '308802908'
    contactInfo['seguroportatilTelephone'] = '308803059'
    contactInfo['prefix'] = '+351'
    contactInfo['whatsapp'] = ''
    contactInfo['holidays'] = ['1-1', '28-2', '15-4', '17-4', '18-4', '25-4', '1-5', '20-5', '10-6', '16-6', '13-6', '15-8', '5-10', '1-11', '1-12', '8-12', '25-12']
    contactInfo['androidLogo'] = require('@/assets/apps/googleplay.png')
    contactInfo['iosLogo'] = require('@/assets/apps/appstoreios.jpg')
    contactInfo['android'] = "https://play.google.com/store/apps/details?id=com.insurama.seusegurotelemovel"
    contactInfo['ios'] = "https://apps.apple.com/pt/app/insurama/id1584188558"
} else {
    contactInfo['telephone'] = '0694808001'
    contactInfo['prefix'] = '+39'
    contactInfo['whatsapp'] = '390694808001'
    contactInfo['androidLogo'] = 'https://www.sella.it/banca-online/static/landing/app-invest/img/google-play-badge@2x.png'
    contactInfo['iosLogo'] = 'https://www.sella.it/banca-online/static/landing/app-invest/img/app-store-badge@2x.png'
    contactInfo['android'] = "https://play.google.com/store/apps/details?id=es.sumbroker.tuseguromovil"
    contactInfo['ios'] = "https://itunes.apple.com/es/app/tuseguromovil/id1435488576?l=es&amp;ls=1&amp;mt=8"
}


module.exports = contactInfo