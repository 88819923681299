import Vue from 'vue'
import landing from '@/config/constants/landing.js'
import i18n from '@/i18n.js'
import { module97, getnumIBAN, isItWorkingHours } from '@/utils/validation.js'
import contactInfo from '@/config/constants/contactInfo';
import dayjs from "dayjs";

Vue.mixin({
    methods: {
        isValidTextField(name, length = 0) {
            var nameNoSpaces = name.trim()
            if (nameNoSpaces != "" && nameNoSpaces.length >= length)
                return true
            else
                return false
        },
        isValidEmail(email) {
            var emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!email.match(emailRegex))
                return false
            else
                return true
        },
        isValidPrice(price) {
            if (price != "") {
                var priceFloat = parseFloat(price)
                if (priceFloat <= 0) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        areValidRisks(risks) {
            var valid = true
            risks.forEach((risk) => {
                if (valid == true) {
                    if (risk.device === '' || !this.isValidPrice(risk.purchase_price)) {
                        valid = false;
                    }
                }
            })
            return valid
        },
        isValidPurchaseDate(currentLanding, date, product_id) {
            var maxDifferenceOfDays = 0
            switch (currentLanding) {
                case landing.seguroportatil.name:
                    maxDifferenceOfDays = 1080
                    break
            }
            var today = new Date(dayjs().format("YYYY-MM-DD"));
            var dateMillis = dayjs(date);
            var todayMillis = dayjs(today);
            var calculatedDiff = todayMillis.diff(dateMillis, 'day');
            if ((product_id == '5' || product_id == '18')) { //Seguro patinete básico y superbásico
                return true
            }

            if (calculatedDiff > maxDifferenceOfDays && maxDifferenceOfDays != 0) {
                return false
            } else {
                return true
            }
        },
        isValidPhone(phone, length = 9) {
            var phoneRegex = /^[+]?[0-9]+$/;

            if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
                length = 9
            }

            phone = phone.replace(/\s/g, '');

            if (phone == "" || phone.length <= length - 1 || phone.length > length || !phone.match(phoneRegex) )
                return false
            else
                return true
        },
        isValidNifNie(nif) {
            if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
                const validationSets = {
                  one: ['1', '2', '3', '5', '6', '8'],
                  two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99']
                };
                if (nif.length !== 9) return false;
                if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) return false;
                const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
                const modulo11 = (Number(total) % 11);
                const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;
                return checkDigit === Number(nif[8]);
            } else {
                var temp = nif.toUpperCase();
                var dniString = "TRWAGMYFPDXBNJZSQVHLCKE";
                if (temp != '') {

                    if (!/^[XYZ]?\d{5,8}[A-Z]$/.test(temp)) {
                        return false;
                    }

                    if (/^[0-9]{8}[A-Z]{1}$/.test(temp)) {
                        var position = nif.substring(8, 0) % 23;
                        var letter = dniString.charAt(position);
                        var letterdni = temp.charAt(8);
                        if (letter == letterdni) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                    if (/^[XYZ]{1}/.test(temp)) {
                        temp = temp.replace('X', '0')
                        temp = temp.replace('Y', '1')
                        temp = temp.replace('Z', '2')
                        var pos = temp.substring(0, 8) % 23;
                        if (nif.toUpperCase().charAt(8) == dniString.substring(pos, pos + 1)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
                return false;
            }
        },
        isValidCardNumber(cardNumber) {
            if (/^[0-9]{16}$/i.test(cardNumber)) {
                return true
            } else {
                return false
            }
        },
        isValidExpirationDateMonthCard(month) {
            if (month >= 1 && month <= 12) {
                return true
            } else {
                return false
            }
        },
        isValidExpirationDateYearCard(year) {
            if (year >= 21 && year <= 50) {
                return true
            } else {
                return false
            }
        },
        isValidCVVCard(cvv) {
            if (cvv.length == 3) {
                return true
            } else {
                return false
            }
        },
        isValidIBAN(iban) {
            if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
                // TODO Basic validations, 
                return new RegExp(/PT[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}([0-9]{1})\s?/).test(iban)
            } else {
                var ibanTmp = iban.toUpperCase();

                ibanTmp = ibanTmp.trim();
                ibanTmp = ibanTmp.replace(/\s/g, "");

                var letter1, letter2, num1, num2;
                var isbanaux;

                if (ibanTmp.length != 24) {
                    return false;
                }

                letter1 = ibanTmp.substring(0, 1);
                letter2 = ibanTmp.substring(1, 2);
                num1 = getnumIBAN(letter1);
                num2 = getnumIBAN(letter2);

                isbanaux = String(num1) + String(num2) + ibanTmp.substring(2);
                isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

                var remainer = module97(isbanaux);
                if (remainer == 1) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isValidCard(userData, policyData) {
            var monthsRemaining = this.monthsRemainingExpirationCard(userData.card_expiration_month, userData.card_expiration_year);
            if (monthsRemaining < 0) {
                return {
                    "valid": false,
                    "message": i18n.t('validation.error_caducity_more'),
                    "ibanRequired": false
                }
            }
            switch (policyData.periodicity.months) {
                case 1:
                    if (monthsRemaining < 12) {
                        if (monthsRemaining < 4) {
                            return {
                                "valid": false,
                                "message": i18n.t('validation.error_caducity_less_12'),
                                "ibanRequired": false
                            }
                        } else {
                            return {
                                "valid": false,
                                "message": i18n.t('validation.error_caducity_less_12'),
                                "ibanRequired": true
                            }
                        }
                    } else {
                        return {
                            "valid": true,
                            "message": "",
                            "ibanRequired": false
                        }
                    }
                case 12:
                    if (monthsRemaining < 12) {
                        return {
                            "valid": false,
                            "message": i18n.t('validation.error_caducity_less_12'),
                            "ibanRequired": true
                        }
                    } else {
                        return {
                            "valid": true,
                            "message": "",
                            "ibanRequired": false
                        }
                    }
            }
            return {
                "valid": false
            }
        },
        monthsRemainingExpirationCard(month, year) {
            if (month.length < 2 && month > 0) {
                month = "0" + month;
            }

            if (year.length < 2 && year > 0) {
                year = "0" + year;
            }
            var expiration = "20" + year + month

            var aux = dayjs(expiration + "01").format("YYYYMM");
            var now = dayjs()
            .date(1)
            .format("YYYYMM");
            var monthsRemaining = (dayjs(aux + "01").diff(now + "01", "month"));
            return (monthsRemaining);
        },
        isRequiredIBAN(month, year) {
            if (month.length < 2 && month > 0) {
                month = "0" + month;
            }

            if (year.length < 2 && year > 0) {
                year = "0" + year;
            }
            var expiration = "20" + year + month

            var aux = dayjs(expiration + "01").format('YYYYMM');
            var now = dayjs().date(1).format('YYYYMM');
            var monthsRemaining = (dayjs(aux + "01").diff(now + "01", "month"));
            return (monthsRemaining);
        },
        isHoliday() {
            var holidays = contactInfo.holidays;
            var itIsWorkingHours = isItWorkingHours();
            if (!itIsWorkingHours) {
                return true;
            }
            var d = new Date();
            var today = d.getDate() + '-' + (d.getMonth() + 1);

            return holidays.some(function(holiday) {
                return holiday === today;
            });
        },
        isValidOrderStep1(currentLanding, policyData) {
            if (currentLanding == landing.seguromultidispositivo.name) {
                if (this.isValidPhone(policyData.phone) && this.areValidRisks(policyData.risks)) {
                    return true
                } else {
                    return false
                }
            } else if (this.isValidPrice(policyData.purchasePrice) && this.isValidPhone(policyData.phone)) {
                if (currentLanding == landing.seguroportatil.name) {
                    if (this.isValidTextField(policyData.reference, 1) && this.isValidTextField(policyData.model, 4) && this.isValidPurchaseDate(currentLanding, policyData.purchaseDate, policyData.product.product_id)) {
                        return true
                    } else {
                        return false
                    }
                } else if (policyData.device != "") {
                    return true
                }
            } else {
                return false
            }
        },
        isValidOrderStep2(userData) {
            let isValid = true

            isValid = this.isValidNifNie(userData.nif) && this.isValidTextField(userData.name, 4) && this.isValidEmail(userData.email) 

            if (userData.acceptance_type > 0 &&
                userData.acceptance_coverages > 0) {
                return isValid
                    
            } else {
                isValid = false
            }

            return isValid
        } // Close isValidOrderStep2
    }
})