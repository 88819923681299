/* Axios */
import axios from 'axios'
import ApiUrls from '@/config/constants/apiUrls.js'

/* Api insurama */
import { Api } from '@insurama/api-client'
var sb = new Api({ apiUrl: process.env.VUE_APP_INSURAMA_API_URL })

import i18n from '@/i18n.js'

// Functions
export async function getStores(params) {
    return await sb.getStores(params)
        .then(response => {
            return response.data.stores
        })
        .catch(() => [])
}

export async function getAllProducts() {
    return await sb.getProducts()
        .then(data => data.data)
        .then(function(data) {
            return data
                .map(function(item) {
                    return item
                })
        })
        .then(function(data) {
            return data
        })
        .catch(function(err) {
            console.log(err)
        })
}

export async function getPolicy(identifier) {
    return await sb.getPolicy(identifier)
        .then(response => {
            return response.data
        })
        .catch(() => [])
}

export async function getPolicyPrice(params) {
    return await sb.getPolicyPrice(params)
        .then(data => data.data)
        .then(function(data) {
            var price = data['price_per_payment'].replace(".", ",");
            return price
        })
        .catch(() => -1)
}

export async function getDeviceModels() {
    return await axios.get(ApiUrls.DEVICE_MODELS)
        .then((response) => {
            return response.data
        })
        .catch(function(err) {
            console.error(err)
        })
}

export async function createOrUpdateGenericItem(params) {
    return await sb.createOrUpdateGenericItem(params)
        .then(response => {
            return response.data
        })
        .catch(() => [])
}

export async function submitPolicy(params) {
    return await sb.createPolicy(params)
        .then(response => {
            if (response.status == 201) {
                return {
                    isValidPolicy: true,
                    payment_result: response.data.payment_result,
                    policy: response.data.policy,
                    form: response.data.form
                }
            } else {
                return {
                    isValidPolicy: false,
                    error: i18n.t('error_ocurred')
                }
            }
        })
        .catch(error => {
            return {
                isValidPolicy: false,
                error: error.message
            }
        })
}

export async function submitContact(params, config) {
    return await axios.post(ApiUrls.CONTACT_POST, params, config)
        .then(response => {
            if (response.status == 201) {
                return {
                    isValidContact: true
                }
            } else {
                return {
                    isValidContact: false,
                    error: i18n.t('error_ocurred')
                }
            }
        })
        .catch(error => {
            return {
                isValidContact: false,
                error: error.message
            }
        })
}

export async function getAppStoresLink() {
    return await axios.get(ApiUrls.APP_STORES_LINKS)
        .then((response) => {
            return response.data
        })
        .catch(function(err) {
            console.error(err)
        })
}
