import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const routerInstance = new VueRouter({
    routes: routes,
    mode: 'history',
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
        return null;
    }
})

export default routerInstance;