/* Custom cookiebot */
/* Perform a redirection of the 'show details' button based on the country */
window.addEventListener('CookiebotOnDialogDisplay', function () {
    const moreDetailsLinkElement = document.getElementById('CybotCookiebotDialogBodyEdgeMoreDetailsLink');

    if (moreDetailsLinkElement) {
        const footerDiv = document.getElementById("CybotCookiebotDialogFooter");
        const customizeButton = document.getElementById("CybotCookiebotDialogBodyLevelButtonCustomize");

        if (moreDetailsLinkElement.parentNode && moreDetailsLinkElement.parentNode != null) {
            const clonedButtonMoreDetails = moreDetailsLinkElement.cloneNode(true);
            moreDetailsLinkElement.parentNode.replaceChild(clonedButtonMoreDetails, moreDetailsLinkElement);

            customizeButton.addEventListener("click", function () {
                applyAdditionalStyles(clonedButtonMoreDetails);
                applyAfterStyles(clonedButtonMoreDetails);
                footerDiv.appendChild(clonedButtonMoreDetails);
            });

            clonedButtonMoreDetails.addEventListener('click', function (e) {
                e.preventDefault();
                window.location.href = '/cookies';
            });
        }
    }
});

function applyAdditionalStyles(element) {
    element.style.alignItems = "center";
    element.style.color = "#ff6716";
    element.style.display = "flex";
    element.style.fontWeight = "600";
    element.style.textDecoration = "none";
    element.style.marginTop = "1rem";
}

function applyAfterStyles(element) {
    const style = document.createElement('style');
    style.innerHTML = `
            ${element.tagName}#${element.id}::after {
                border-style: solid;
                border-width: .15em .15em 0 0;
                color: #141414;
                content: "";
                display: block;
                height: .563em;
                margin-left: 1em;
                transform: rotate(45deg);
                width: .563em;
            }
        `;

    document.head.appendChild(style);
}